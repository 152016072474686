<form [nbSpinner]="loading" nbSpinnerSize="giant" autocomplete="off">
  <fieldset [disabled]="loading">
    <div class="form-group" [formGroup]="accountSettingsForm" (ngSubmit)="onSubmit()">
      <h6>Business Details</h6>
      <input type="text" required nbInput fullWidth id="businessName" placeholder="Business name"
             formControlName="businessName"
             status="{{isTouchedAndInvalid('businessName')? 'danger' : 'basic' }}">
      <div class="text-danger" *ngIf="isTouchedAndInvalid('businessName')">
        <span>Business name is required</span>
      </div>

      <div class="file-upload">

        <div class="text-secondary">Logo</div>

        <input required type="file" accept="image/*" class="file-input" id="logo"
               (change)="onFileSelected($event)" #fileUpload>

        <img class="logo-preview" src="{{this.logoFile}}" *ngIf="this.logoFile; else noLogo">
        <ng-template #noLogo>
          <span><i>No logo uploaded yet</i></span>
        </ng-template>
        <div class="text-danger" *ngIf="isTouchedAndInvalid('logo')">
          <span>Logo is required</span>
        </div>

        <button nbButton class="fa-pull-right" (click)="fileUpload.click(); $event.preventDefault()">
          <nb-icon icon="upload-outline"/>
        </button>

      </div>

      <div class="form-group">
        <input type="email" required nbInput fullWidth id="emailAddress" placeholder="Business email"
               formControlName="emailAddress" status="{{isTouchedAndInvalid('emailAddress')? 'danger' : 'basic' }}"
               email>
        <div class="text-danger" *ngIf="isTouchedAndInvalid('emailAddress')">
          <span>Must be a valid email</span>
        </div>
      </div>
      <div class="form-group">
        <input type="text" nbInput fullWidth id="taxNumber" placeholder="Tax Number"
               formControlName="taxNumber">
      </div>
      <div class="form-group">
        <nb-form-field>
          <input type="number" max="100" min="0" required nbInput fullWidth id="taxRate"
                 placeholder="Tax Rate"
                 formControlName="taxRate"
                 status="{{isTouchedAndInvalid('taxRate')? 'danger' : 'basic' }}">
          <span nbSuffix>%</span>
        </nb-form-field>
        <div class="text-danger" *ngIf="isTouchedAndInvalid('taxRate')">
          <span>Must be a number between 0% and 100%</span>
        </div>
      </div>
      <div class="form-group">
        <nb-form-field>
          <input type="number" max="60" min="1" required nbInput fullWidth id="invoiceGracePeriod"
                 placeholder="Invoice Grace Period"
                 formControlName="invoiceGracePeriod"
                 status="{{isTouchedAndInvalid('invoiceGracePeriod')? 'danger' : 'basic' }}">
          <span nbSuffix>days</span>
          <div class="text-danger" *ngIf="isTouchedAndInvalid('invoiceGracePeriod')">
            <span>Must be a number between 1 and 60</span>
          </div>
        </nb-form-field>
      </div>
      <h6>Address</h6>
      <div class="form-group">
        <div class="form-group">
          <input type="text" nbInput fullWidth id="addressLine1" placeholder="Address Line 1"
                 formControlName="addressLine1">
        </div>
        <div class="form-group">
          <input type="text" nbInput fullWidth id="addressLine2" placeholder="Address Line 2"
                 formControlName="addressLine2">
        </div>
        <div class="form-group">
          <input type="text" nbInput fullWidth id="city" placeholder="City" formControlName="city">
        </div>
        <div class="form-group">
          <input type="text" nbInput fullWidth id="state" placeholder="State" formControlName="state">
        </div>
        <div class="form-group">
          <input type="text" nbInput fullWidth id="postCode" placeholder="Post Code" formControlName="postCode">
        </div>
        <div class="form-group">
          <input type="text" nbInput fullWidth id="country" placeholder="Country" formControlName="country">
        </div>
      </div>
      <div class="fa-pull-right">
        <button class='cancel-button' disabled="{{submitting}}" (click)="$event.preventDefault(); closeWindow()" ghost
                nbButton status="basic">Cancel
        </button>
        <button [nbSpinner]="submitting" type="submit" disabled="{{!accountSettingsForm.valid || submitting}}"
                nbButton
                (click)="$event.preventDefault(); onSubmit()"
                status="primary">Save
        </button>
      </div>
    </div>
  </fieldset>
</form>
