import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'enum'})
export class EnumPipe implements PipeTransform {
  transform(enumValue: string, ignoredValues: string[]): string {

    if (ignoredValues?.find(v => v === enumValue)) {
      return '';
    }

    if (!enumValue)
      return '';

    return this.capitalizeFirstLetter(enumValue
      .toLowerCase()
      .replace('_', ' '));
  }

  private capitalizeFirstLetter(str: string) {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1);
  }
}
