/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface InvoicesInvoiceIdPaymentsPaymentIdActionsGenerateReceiptPdfPost$Params {

/**
 * ID of the Invoice to Generate Receipt for
 */
  invoiceId: string;

/**
 * ID of the Payment to Generate Receipt for
 */
  paymentId: string;
}

export function invoicesInvoiceIdPaymentsPaymentIdActionsGenerateReceiptPdfPost(http: HttpClient, rootUrl: string, params: InvoicesInvoiceIdPaymentsPaymentIdActionsGenerateReceiptPdfPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, invoicesInvoiceIdPaymentsPaymentIdActionsGenerateReceiptPdfPost.PATH, 'post');
  if (params) {
    rb.path('invoiceId', params.invoiceId, {});
    rb.path('paymentId', params.paymentId, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/pdf', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

invoicesInvoiceIdPaymentsPaymentIdActionsGenerateReceiptPdfPost.PATH = '/invoices/{invoiceId}/payments/{paymentId}/actions/generate-receipt-pdf';
